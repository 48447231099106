"use client"

import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit"
import {
  braveWallet,
  coinbaseWallet,
  ledgerWallet,
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactNode } from "react"
import { createClient, http } from "viem"
import { createConfig, WagmiProvider } from "wagmi"
import * as wagmiChains from "wagmi/chains"
import "@rainbow-me/rainbowkit/styles.css"

const projectId = "1ccb34ad36d5e2a17e4ab707d78432db"

const connectors = connectorsForWallets(
  [
    {
      groupName: "Popular",
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        coinbaseWallet,
        walletConnectWallet,
      ],
    },
    {
      groupName: "Additional",
      wallets: [trustWallet, ledgerWallet, braveWallet],
    },
  ],
  {
    appName: "Voxies Marketplace",
    projectId,
  }
)

export const wagmiConfig = createConfig({
  ssr: true,
  connectors,
  chains: Object.values(wagmiChains) as any,
  client({ chain }) {
    return createClient({ chain, transport: http() })
  },
})

export const queryClient = new QueryClient()

interface RainbowWagmiProviderProps {
  children: ReactNode
}

export const RainbowWagmiProvider = ({
  children,
}: RainbowWagmiProviderProps) => (
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider theme={darkTheme()}>{children}</RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
)
